<template>
  <div>
    <template v-if="setup === false">
      <v-alert border="top" color="red lighten-2" dark>
        An alert occurred. Please try again.
      </v-alert>
    </template>

    <template v-else>
      <h3 class="page-header">Rater Configuration</h3>

      <template v-if="setup !== null && setup.allowed_users_count === 0">
        <v-alert border="left" color="red lighten-2" dark>
          Rater is not enabled on your account
        </v-alert>
      </template>

      <template v-else>
        <!-- Successful confirmation message -->
        <v-snackbar v-model="updateSuccessful" :timeout="2000" centered :multi-line="true">
          Configuration for your Rater account saved!

          <template v-slot:action="{ attrs }">
            <v-btn color="primary" text @click="updateSuccessful = false">Close</v-btn>
          </template>
        </v-snackbar>

        <div v-if="setup !== null" style="display: flex; margin-bottom: 20px; align-items: center">
          <div style="flex:1">
            You are allowed to have <strong>{{ maxUsers }}</strong> users for your Rater account
          </div>

          <div v-if="setup.sso">
            <form method="post" :action="setup.sso.url" target="_blank">
              <input type="hidden" name="Authorization" :value="setup.sso.token"/>
              <v-btn small color="primary" type="submit">Connect to Dashboard</v-btn>
            </form>
          </div>
        </div>

        <!-- Table of all users -->
        <v-data-table
          :headers="tableHeaders"
          :items="tableItems"
          :loading="setup === null"
          :hide-default-footer="true"
          :disable-pagination="true">

          <template v-slot:item.enabled="{ item }">
            <div style="display: flex">
              <v-icon v-if="currentSelection[item.id] !== 'none'" class="ba-success--text"
                      style="margin-right: 10px">check
              </v-icon>
              <v-select
                :items="rolesOptions[item.id]"
                v-model="currentSelection[item.id]">
              </v-select>
            </div>
          </template>
        </v-data-table>

        <!-- Footer to save users -->
        <div v-if="setup !== null" style="margin-top: 20px; text-align: right">
          Enabled:
          <strong :class="{'ba-danger--text': selectedCount > maxUsers}" style="margin-right: 15px">{{ selectedCount }} / {{ maxUsers }}</strong>

          <v-btn :disabled="hasOneAdmin === false || isSubmitting || hasChanges === false || selectedCount > maxUsers" color="primary" @click="submitRaterSetup">
            <template v-if="isSubmitting">
              Please wait...
            </template>
            <template v-else>
              {{ hasOneAdmin ? 'Submit Changes' : 'Select at least 1 admin' }}
            </template>
          </v-btn>
        </div>

      </template>
    </template>
  </div>
</template>

<style lang="scss" scoped>
.page-header {
  font-family: 'Roboto', serif;
  font-size: 32px;
  color: #422F2F;
  margin-bottom: 15px;
}
</style>

<script>
import {mapGetters} from "vuex";
import store from "@/config/store";

export default {
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),

    hasOneAdmin() {
      let count = 0;

      _.forEach(this.currentSelection, (value) => {
        if (value === 'admin') {
          count++;
        }
      });

      return count > 0;
    },

    selectedCount() {
      let count = 0;
      _.forEach(this.currentSelection, (value) => {
        if (value !== 'none') {
          count++;
        }
      });

      return count;
    },

    hasChanges() {
      return !_.isEqual(this.currentSelection, this.previousSelection)
    },

    maxUsers() {
      return this.setup.allowed_users_count;
    },

    tableHeaders() {
      return [
        {
          text:     'Name',
          align:    'start',
          sortable: true,
          value:    'name',
          width:    '30%'
        },
        {
          text:     'Email',
          align:    'start',
          sortable: true,
          value:    'email',
        },
        {
          text:     'Enabled',
          align:    'end',
          width:    '200px',
          sortable: false,
          value:    'enabled'
        }
      ];
    },

    tableItems() {
      let users = [];

      if (this.setup) {
        _.forEach(this.setup.users, (user) => {
          users.push({
            id:    user.id,
            name:  user.attributes.name,
            email: user.attributes.email
          });
        });
      }

      return users;
    }
  },

  methods: {
    loadRaterSetup() {
      this.$api.rater.loadSetup()
        .then((response) => {
          this.setRaterData(response.data);
        })
        .catch(() => {
          this.setup = false;
        });
    },

    submitRaterSetup() {
      this.isSubmitting = true;

      this.$api.rater.saveSetup({users: this.currentSelection})
        .then((response) => {
          this.setRaterData(response.data);

          return store.dispatch('loadAccount').then(() => {
            this.updateSuccessful = true;
          });
        })
        .catch((error) => {
          alert('An error occurred!');
        })
        .then(() => {
          this.isSubmitting = false;
        });
    },

    setRaterData(data) {
      this.setup            = data;
      this.currentSelection = {};

      _.forEach(this.setup.users, (user) => {
        this.$set(this.currentSelection, user.id, user.attributes.enabled ? user.attributes.role : 'none');

        let rolesOptions = [{'value': 'none', 'text': 'Disabled'},];

        if (user.attributes.role === null || user.attributes.role === 'user') {
          rolesOptions.push({'value': 'user', 'text': 'User'});
        }
        if (user.attributes.role === null || user.attributes.role === 'admin') {
          rolesOptions.push({'value': 'admin', 'text': 'Admin'});
        }

        this.rolesOptions[user.id] = rolesOptions;
      });

      this.previousSelection = _.cloneDeep(this.currentSelection);
    }
  },

  beforeMount() {
    this.loadRaterSetup();
  },

  data() {
    return {
      setup:             null,
      isSubmitting:      false,
      updateSuccessful:  false,
      rolesOptions:      {},
      currentSelection:  {},
      previousSelection: {}
    }
  }
}
</script>
